<template>
  <div>
    <child :showButton="showButton"></child>
  </div>
</template>

<script>
import docuAbout from '@/views/component/docuCommon/docuAbout';
export default {
  name: 'NpsmEditAbout',
  components: {
    child: docuAbout
  },
  props: {
    showButton: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped></style>
