<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <div class="vd_edhea vg_button_group">
      <editHeader
        :isShow="isShow"
        :btn="btn"
        :strForm="npsmForm"
        @openEdit="openEdit"
        @closeEdit="closeEdit"
        @submit="submit('npsmForm')"
      />
      <el-button type="primary" size="small" class="vd_export" @click="buttonRefresh()" v-if="isShow">刷新</el-button>
    </div>
    <el-form ref="npsmForm" :model="npsmForm" :rules="rules" label-width="120px" size="mini" :disabled="isShow">
      <el-row>
        <el-col :md="8">
          <el-form-item label="物料图片">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              disabled
              :data="dataBody"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <imgPopover v-if="npsmForm.imge_id" :imgeUrl="npsmForm.imge_url" :imgeUrlO="npsmForm.imge_urlO"></imgPopover>
              <i v-else class="el-icon-plus avatar-uploader-icon vg_pointer"></i>
            </el-upload>
            <span>仅支持jpg、png、jpeg格式且大小不能超过10MB</span>
          </el-form-item>
        </el-col>
        <el-col :md="16">
          <el-row class="vg_mb_5">
            <!-- <el-col :md="12">
              <el-form-item label="委托单号" prop="requ_no">
                <el-input maxlength="10" disabled v-model="npsmForm.requ_no" @input ="npsmForm.requ_no = helper.keepEngNum2(npsmForm.requ_no)" placeholder="请填写委托单号" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>   -->
            <el-col :md="12">
              <el-form-item label="样品系列名" prop="smpl_theme">
                <el-input
                  maxlength="40"
                  v-model="npsmForm.smpl_theme"
                  @input="npsmForm.smpl_theme = helper.NotkeepChin(npsmForm.smpl_theme)"
                  placeholder="请填写样品系列名"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="样品编号" prop="smpl_no">
                <el-input
                  maxlength="10"
                  v-model="npsmForm.smpl_no"
                  @input="npsmForm.smpl_no = helper.keepEngNum2(npsmForm.smpl_no)"
                  placeholder="请填写样品编号"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="客户样品数量" prop="smpl_cust_num">
                <el-input
                  maxlength="9"
                  v-model="npsmForm.smpl_cust_num"
                  @input="npsmForm.smpl_cust_num = helper.keepTNum(npsmForm.smpl_cust_num)"
                  placeholder="请填写客户样品数量"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
            <!-- </el-row>
          <el-row class="vg_mb_5"> -->
            <!-- <el-col :md="12">
              <el-form-item label="样品名称" prop="smpl_name">
                <el-input maxlength="30" disabled v-model="npsmForm.smpl_name" placeholder="请填写样品名称" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="委托类型" prop="smpl_quot">
                <el-select v-model="npsmForm.smpl_quot" placeholder="请选择委托类型" filterable npsmForm>
                  <el-option
                    v-for="item in smplQuotList"
                    :key="item.id"
                    :label="item.label"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <!-- </el-row>
          <el-row class="vg_mb_5"> -->
            <el-col :md="12">
              <el-form-item label="样品英文名称" prop="smpl_name_en">
                <el-input
                  maxlength="100"
                  v-model="npsmForm.smpl_name_en"
                  @input="npsmForm.smpl_name_en = helper.keepEnglish1(npsmForm.smpl_name_en)"
                  placeholder="请填写样品英文名称"
                  show-word-limit
                >
                </el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :md="12">
              <el-form-item label="样品类型" prop="smpl_type">
                <el-select disabled v-model="npsmForm.smpl_type" placeholder="请选择样品类型" filterable npsmForm>
                  <el-option
                        v-for="item in smplType"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
          </el-row>
          <el-row class="vg_mb_5">
            <el-col :md="12">
              <el-form-item label="委托人" prop="npsm_stff_name">
                <el-select
                  filterable
                  v-model="npsmForm.npsm_stff_name"
                  @change="npsmStffChange"
                  placeholder="请选择委托人"
                  size="small"
                  npsmForm
                >
                  <el-option v-for="item in stffType" :key="item.user_id" :label="item.stff_name" :value="item.stff_name"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="客户名称" prop="cust_abbr">
                <el-input disabled v-model="npsmForm.cust_abbr" show-word-limit placeholder="请选择客户名称" @click.native="custImport()">
                  <template slot="append">
                    <el-link type="primary">选择</el-link>
                  </template>
                  {{ npsmForm.cust_name }}
                </el-input>
              </el-form-item>
              <el-dialog :title="CustTitle" :visible.sync="custFlag" width="70%">
                <NpsmCust @custID="custID" @cancelCust="cancelCust"></NpsmCust>
              </el-dialog>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <div class="vd_solidDiv"></div>
      <el-row class="vg_mb_16">
        <!-- <el-col :md="8" class="vd_vis">111</el-col> -->
        <el-col :md="8" class="vd_dis">样品模具Sample Mold</el-col>
        <el-col :md="8" class="vd_dis">大货模具Production Mold</el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <!-- <el-col :md="8">
          <el-form-item label="外观3D状态" prop="appear_status">
            <el-select v-model="npsmForm.appear_status" placeholder="请选择外观3D状态" filterable npsmForm>
              <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.label"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :md="8">
          <el-form-item label="通知开模状态" prop="smpl_openmold_status">
            <el-select v-model="npsmForm.smpl_openmold_status" placeholder="请选择通知开模状态" filterable npsmForm>
              <el-option v-for="item in stateList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="通知开模状态" prop="prod_openmold_status">
            <el-select v-model="npsmForm.prod_openmold_status" placeholder="请选择通知开模状态" filterable npsmForm>
              <el-option v-for="item in stateList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <!-- <el-col :md="8">
          <el-form-item label="结构3D状态" prop="struct_status">
            <el-select v-model="npsmForm.struct_status" placeholder="请选择结构3D状态" filterable npsmForm>
              <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.label"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :md="8">
          <el-form-item label="模具完成状态" prop="smpl_moldcomp_status">
            <el-select v-model="npsmForm.smpl_moldcomp_status" placeholder="请选择模具完成状态" filterable npsmForm>
              <el-option v-for="item in stateList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="模具完成状态" prop="prod_moldcomp_status">
            <el-select v-model="npsmForm.prod_moldcomp_status" placeholder="请选择模具完成状态" filterable npsmForm>
              <el-option v-for="item in stateList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <!-- <el-col :md="8">
          <el-form-item label="色块样状态" prop="colrswat_status">
            <el-select v-model="npsmForm.colrswat_status" placeholder="请选择色块样状态" filterable npsmForm>
              <el-option
                v-for="item in stateList"
                :key="item.id"
                :label="item.label"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :md="8">
          <el-form-item label="交样状态" prop="smpl_delismpl_status">
            <el-select v-model="npsmForm.smpl_delismpl_status" placeholder="请选择交样状态" filterable npsmForm>
              <el-option v-for="item in stateList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="交样状态" prop="prod_delismpl_status">
            <el-select v-model="npsmForm.prod_delismpl_status" placeholder="请选择交样状态" filterable npsmForm>
              <el-option v-for="item in stateList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="vd_solidDiv"></div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="外观&结构3D状态" name="first">
          <SubtableList
            :labelName="'外观&结构3D状态'"
            :tableData="npsmForm.appearStatusList"
            @addRow="addRowAppear"
            @delBom="delAppear"
            @stateChange="stateChangeAppear"
          ></SubtableList>
        </el-tab-pane>
        <!--<el-tab-pane label="结构3D状态" name="second">-->
        <!--  <SubtableList :labelName="'结构3D状态'" :tableData="npsmForm.structStatusList" @addRow="addRowStruct" @delBom="delStruct" @stateChange="stateChangeStruct"></SubtableList>-->
        <!--</el-tab-pane>-->
        <el-tab-pane label="3D样状态" name="third">
          <SubtableList
            :labelName="'3D样状态'"
            :tableData="npsmForm.kindStatusList"
            @addRow="addRowKind"
            @delBom="delKind"
            @stateChange="stateChangeKind"
          ></SubtableList>
        </el-tab-pane>
        <el-tab-pane label="色块样状态" name="fourth">
          <SubtableList
            :labelName="'色块样状态'"
            :tableData="npsmForm.colrswatStatusList"
            @addRow="addRowColrswat"
            @delBom="delColrswat"
            @stateChange="stateChangeColrswat"
          ></SubtableList>
        </el-tab-pane>
      </el-tabs>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="false" :stffForm="stffForm" ref="userData" v-if="npsmForm.stff_name"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { npsmAPI } from '@api/modules/npsm';
import { imgeAPI } from '@api/modules/imge';
import { userAPI } from '@api/modules/user';
import editHeader from '@/views/component/editHeader';
import inputUser from '@/views/component/inputUser';
import imgPopover from '@/views/component/imgPopover';
import NpsmCust from './Componet/NpsmCust.vue';
import SubtableList from './Componet/SubtableList.vue';

export default {
  name: 'npsmEditMain',
  components: {
    editHeader,
    inputUser,
    imgPopover,
    NpsmCust,
    SubtableList
  },
  data() {
    return {
      rules: {
        smpl_theme: [{ required: true, trigger: 'blur', message: ' ' }],
        smpl_no: [{ required: true, trigger: 'blur', message: ' ' }],
        smpl_cust_num: [{ required: true, trigger: 'blur', message: ' ' }],
        smpl_name_en: [{ required: true, trigger: 'blur', message: ' ' }],
        npsm_stff_name: [{ required: true, trigger: 'blur', message: ' ' }],
        cust_abbr: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      npsmForm: {
        imge_id: null,
        imge_url: null,
        imge_urlO: null,
        appearStatusList: [],
        structStatusList: [],
        colrswatStatusList: [],
        kindStatusList: []
      },
      stffForm: {},
      isShow: true,
      btn: {},
      uploadUrl: this.helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 800,
        y: 800
      },
      imgForm: {
        imge_id: null,
        imge_url: null,
        imge_urlO: null
      },
      loadingFlag: false,
      smplQuotList: [
        { id: 1, label: '打样并报价' },
        { id: 2, label: '打样' },
        { id: 3, label: '报价' }
      ],
      smplType: [
        { id: 1, label: '新款' },
        { id: 2, label: '老款' },
        { id: 3, label: '修改款' }
      ],
      stateList: [
        { id: 0, label: '未经历' },
        { id: 1, label: '已完成' },
        { id: 2, label: '不经历' },
        { id: 3, label: '正在经历' }
      ],
      show: false,
      stffType: [],
      custFlag: false,
      CustTitle: '选择客户',
      activeName: 'first'
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getnpsmInfo();
      this.getStffType();
    },
    //启用编辑
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    //撤销编辑
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.show = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      let npsmForm;
      // npsmForm = Object.assign(npsmForm,this.npsmForm);
      npsmForm = JSON.parse(JSON.stringify(this.npsmForm));
      let temp1 = [];
      let temp2 = [];
      let temp3 = [];
      let temp4 = [];
      npsmForm.appearStatusList.forEach(item => temp1.push(item.value));
      npsmForm.structStatusList.forEach(item => temp2.push(item.value));
      npsmForm.colrswatStatusList.forEach(item => temp3.push(item.value));
      npsmForm.kindStatusList.forEach(item => temp4.push(item.value));
      npsmForm.appear_status = temp1.join(',');
      npsmForm.struct_status = temp2.join(',');
      npsmForm.colrswat_status = temp3.join(',');
      npsmForm.kind_status = temp4.join(',');
      delete npsmForm.appearStatusList;
      delete npsmForm.structStatusList;
      delete npsmForm.colrswatStatusList;
      delete npsmForm.kindStatusList;
      post(npsmAPI.editNpsm, npsmForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.initData();
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            // this.initData()
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('npsmForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 更换委托人获取英文名
    npsmStffChange() {
      for (let i = 0; i < this.stffType.length; i++) {
        if (this.npsmForm.npsm_stff_name === this.stffType[i].stff_name) {
          this.npsmForm.npsm_stff_ename = this.stffType[i].stff_ename;
        }
      }
    },
    // 色块样状态
    addRowColrswat() {
      let item = {
        value: 0,
        key: this.npsmForm.colrswatStatusList.length
      };
      this.npsmForm.colrswatStatusList.push(item);
    },
    // 删除色块样状态
    delColrswat(val) {
      if (this.npsmForm.colrswatStatusList.length > 1) {
        this.npsmForm.colrswatStatusList.splice(val, 1);
      } else {
        this.$message.warning('必须保留一条数据');
      }
    },
    // 更改色块样状态
    stateChangeColrswat(val, val1) {
      this.npsmForm.colrswatStatusList[val].value = val1;
    },
    // 结构3D状态
    addRowStruct() {
      let item = {
        value: 0,
        key: this.npsmForm.structStatusList.length
      };
      this.npsmForm.structStatusList.push(item);
    },
    // 删除结构3D状态
    delStruct(val) {
      if (this.npsmForm.structStatusList.length > 1) {
        this.npsmForm.structStatusList.splice(val, 1);
      } else {
        this.$message.warning('必须保留一条数据');
      }
    },
    // 更改结构3D状态值
    stateChangeStruct(val, val1) {
      this.npsmForm.structStatusList[val].value = val1;
    },
    // 3D样状态
    addRowKind() {
      let item = {
        value: 0,
        key: this.npsmForm.kindStatusList.length
      };
      this.npsmForm.kindStatusList.push(item);
    },
    // 删除结构3D状态
    delKind(val) {
      if (this.npsmForm.kindStatusList.length > 1) {
        this.npsmForm.kindStatusList.splice(val, 1);
      } else {
        this.$message.warning('必须保留一条数据');
      }
    },
    // 更改结构3D状态值
    stateChangeKind(val, val1) {
      this.npsmForm.kindStatusList[val].value = val1;
    },
    // 外观3d状态新增
    addRowAppear() {
      let item = {
        value: 0,
        key: this.npsmForm.appearStatusList.length
      };
      this.npsmForm.appearStatusList.push(item);
    },
    // 删除外观3d状态
    delAppear(val) {
      if (this.npsmForm.appearStatusList.length > 1) {
        this.npsmForm.appearStatusList.splice(val, 1);
      } else {
        this.$message.warning('必须保留一条数据');
      }
    },
    // 更改3d状态值
    stateChangeAppear(val, val1) {
      this.npsmForm.appearStatusList[val].value = val1;
    },
    // 关闭弹框
    cancelCust() {
      this.custFlag = false;
    },
    //打开客户弹窗
    custImport() {
      this.custFlag = true;
    },
    custID(val) {
      if (val.cust_id) {
        this.npsmForm.cust_id = val.cust_id;
        this.npsmForm.cust_abbr = val.cust_abbr;
      } else {
        this.npsmForm.cust_id = 0;
        this.npsmForm.cust_abbr = 0;
      }
      if (val.cust_pid) {
        this.npsmForm.cust_pid = val.cust_pid;
      } else {
        this.npsmForm.cust_pid = val.cust_id;
      }
      this.custFlag = false;
    },
    // 刷新按钮
    buttonRefresh() {
      this.initData();
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        this.npsmForm.imge_id = res.data.imge_id;
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.npsmForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
        this.npsmForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      } else {
        let mg = res.msg;
        let tp = 'error';
        this.$message({ message: mg, type: tp });
      }
    },
    // 上传头像前
    beforeAvatarUpload() {},
    // 获取委托人
    getStffType() {
      get(userAPI.getAllUsersV1)
        .then(res => {
          if (res.data.code === 0) {
            let stffType = res.data.data;
            let map = new Map();
            for (let item of stffType) {
              map.set(item.stff_name, item);
            }
            stffType = [...map.values()];
            this.stffType = stffType;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取表单信息
    getnpsmInfo() {
      this.npsmForm.npsm_id = this.$route.query.form_id;
      get(npsmAPI.getNpsmById, { npsm_id: this.npsmForm.npsm_id })
        .then(res => {
          if (res.data.code === 0) {
            this.npsmForm = res.data.data.form;
            let urlEng = JSON.parse(JSON.stringify(this.npsmForm.imge_url));
            let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
            this.npsmForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
            this.npsmForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
            if (this.show) {
              this.npsmForm.imge_id = this.imgForm.imge_id;
              this.npsmForm.imge_url = this.imgForm.imge_url;
              this.npsmForm.imge_urlO = this.imgForm.imge_urlO;
              this.show = false;
            }
            this.imgForm.imge_url = JSON.parse(JSON.stringify(this.npsmForm.imge_url));
            this.imgForm.imge_id = JSON.parse(JSON.stringify(this.npsmForm.imge_id));
            this.imgForm.imge_urlO = JSON.parse(JSON.stringify(this.npsmForm.imge_urlO));
            this.btn = res.data.data.btn;
            this.stffForm.stff_name = this.npsmForm.stff_name;
            this.stffForm.dept_name = this.npsmForm.dept_name;
            this.stffForm.dept_team_name = this.npsmForm.dept_team_name;
            this.stffForm.user_id = this.npsmForm.user_id;
            this.stffForm.dept_id = this.npsmForm.dept_id;
            this.stffForm.stff_id = this.npsmForm.stff_id;
            this.calc();
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 计算
    calc() {
      console.log('this.npsmForm', this.npsmForm);
      let temp1 = [];
      let temp2 = [];
      let temp3 = [];
      let temp4 = [];
      this.npsmForm.appearStatusList.forEach((val, index) => {
        let item = {};
        item.key = Number(index);
        item.value = Number(val);
        temp1.push(item);
      });
      this.npsmForm.appearStatusList = temp1;
      this.npsmForm.structStatusList.forEach((val, index) => {
        let item = {};
        item.key = Number(index);
        item.value = Number(val);
        temp2.push(item);
      });
      this.npsmForm.structStatusList = temp2;
      this.npsmForm.colrswatStatusList.forEach((val, index) => {
        let item = {};
        item.key = Number(index);
        item.value = Number(val);
        temp3.push(item);
      });
      this.npsmForm.colrswatStatusList = temp3;
      this.npsmForm.kindStatusList.forEach((val, index) => {
        let item = {};
        item.key = Number(index);
        item.value = Number(val);
        temp4.push(item);
      });
      this.npsmForm.kindStatusList = temp4;
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_solidDiv {
  border-top: #dcdfe6 solid 1px;
  margin: 20px 0;
}
.vd_vis {
  visibility: hidden;
}
.vd_dis {
  display: flex;
  justify-content: center;
}
.vd_export {
  position: absolute;
  top: 0;
  left: 65px;
}
</style>
